import React from "react";

const FooterBlack = () => {
  return (
    <div class="footer font-satoshi-bold">
      <div class="logoWrapper">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/acros-resources.appspot.com/o/Acros-files%2Fboosted-white-full.svg?alt=media&token=2ee96806-7efe-45ce-8b7f-26e9ecfd17ec"
          alt=""
          style={{ height: "100px" }}
        />
      </div>
      <div class="linksWrapper">
        <div class="linksBox text-left">
          <p class="h2">Company</p>

          <ul class="ul font-satoshi-regular">
            <li>
              <a href="/"> Home </a>
            </li>
            <li>
              <a href="/agency"> Agency </a>
            </li>
            {/* <li>
              <a href="/technology"> Technology </a>
            </li>
            <li>
              <a href="/operations"> Operations </a>
            </li> */}
            <li>
              <a href="/career"> Career </a>
            </li>
            <li>
              <a href="/contact-us"> Contact Us </a>
            </li>
            <li>
              <a href="/about-us"> About Us </a>
            </li>
          </ul>
        </div>
        {/* <div class="linksBox">
          <p class="h2">Resources</p>

          <ul class="ul font-satoshi-regular">
            <li>
              <a href="/resources"> Articles </a>
            </li>
            <li>
              <a href="/resources"> Blogs </a>
            </li>
            <li>
              <a href="/resources"> Case Study </a>
            </li>
          </ul>
        </div> */}
        <div class="linksBox">
          <p class="h2">Specializations</p>

          <ul class="ul font-satoshi-regular">
            <li>
              <a href="/agency"> PPC Ads </a>
            </li>
            <li>
              <a href="/agency"> DSP Ads </a>
            </li>
            <li>
              <a href="/agency"> Native Ads </a>
            </li>
            <li>
              <a href="/agency"> Paid Social Ads </a>
            </li>
          </ul>
        </div>
        <div class="linksBox">
          <p class="h2">Get In Touch</p>

          <ul class="ul font-satoshi-regular">
            <li>
              <a href="mailto:hello@boosted.in"> hello@boosted.in </a>
            </li>
            <li>
              {/* <a href="https://www.facebook.com/people/Acros/100066930852884/"> */}
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Boosted-100464635109575"
              >
                Facebook
              </a> */}
            </li>
            <li>
              {/* <a href="https://www.instagram.com/acros.advertising/"> */}
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/boosted.ecommerce/"
              >
                Instagram
              </a> */}
            </li>
            <li>
              {/* <a href="https://www.linkedin.com/company/acros-advertising/"> */}
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/boosted-in/"
              >
                LinkedIn
              </a> */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterBlack;
