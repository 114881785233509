import React from "react";
import strings from "../../strings";
import { Link } from "react-router-dom";

const Section12 = () => {
  return (
    <div className="w-screen bg-black relative  h-[100vh]">
      <video
        width="auto"
        className="max-w-fit"
        autoPlay
        loop
        muted
        style={{
          height: "100%",
        }}
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/acros-resources.appspot.com/o/Acros-files%2Facros-bg-video.mp4?alt=media"
          type="video/mp4"
        />
      </video>
      <div className=" absolute top-0 bottom-0 left-0 right-0 text-center flex flex-col  px-12 justify-center text-center items-center">
        <div className="text-7xl font-extrabold leading-10 text-[#ffffffe0] leading-[80px] mb-[14px]">
          Boost Your <br />
          <span className="gradient-heading animated-gradient-heading">
            E-Commerce Advertising
          </span>
        </div>
        <h3 className="cc-name large silver-text w-[40%]">
          {strings.HOME_SECTION_2_DESCRIPTION}
        </h3>

        <Link to={"/contact-us"}>
          <button
            onClick={(e) => {}}
            className="mt-4 rounded-lg px-10 py-3 text-base font-bold text-white transition duration-400 hover:bg-[#392a62] bg-[#6936f5]"
          >
            Let's Talk
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Section12;
