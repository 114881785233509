import React from "react";
import Section1 from "../components/Contact/Section1";
import Section2 from "../components/Contact/Section2";

const Contact = () => {
  return (
    <div>
      <Section1 />
      <Section2 />
    </div>
  );
};

export default Contact;
